import React from 'react'

/**
 * Renders the left arrow for the Hero.
 * @category Layout Helper
 * @function HeroLeftArrow
 * @param {Function} onClick
 * @returns {jsx}
 */
export default function HeroLeftArrow({ onClick }) {
  return (
    <svg
      className="hero-arrow__left"
      baseProfile="tiny"
      xmlns="http://www.w3.org/2000/svg"
      width={40}
      height={40}
      viewBox="-11.321 -11.321 315 315"
      onClick={onClick}
    >
      <path d="M210.132 297.359c-6.302 0-11.814-2.319-16.385-6.894L65.842 162.56c-4.571-4.571-6.888-10.084-6.888-16.383 0-6.296 2.317-11.808 6.887-16.381L193.749 1.888C198.316-2.682 203.829-5 210.132-5s11.815 2.318 16.384 6.889c4.571 4.571 6.89 10.083 6.891 16.381v255.813c0 6.304-2.319 11.816-6.894 16.385-4.569 4.572-10.082 6.891-16.381 6.891z" />
      <path
        fill="#FFF"
        d="M210.132 0c4.948 0 9.232 1.807 12.848 5.424 3.617 3.617 5.426 7.898 5.427 12.848v255.813c0 4.949-1.81 9.234-5.427 12.848-3.615 3.617-7.899 5.428-12.848 5.428-4.949 0-9.233-1.811-12.848-5.428L69.378 159.025c-3.614-3.615-5.424-7.898-5.424-12.848 0-4.949 1.81-9.23 5.424-12.847L197.284 5.424C200.899 1.806 205.183 0 210.132 0m0-10c-7.569 0-14.458 2.889-19.921 8.354L62.306 126.259c-5.465 5.47-8.353 12.356-8.353 19.918 0 7.565 2.888 14.453 8.352 19.918l127.907 127.907c5.462 5.466 12.351 8.356 19.919 8.356 7.567 0 14.455-2.891 19.921-8.358 5.463-5.457 8.354-12.347 8.354-19.917V18.271c-.001-7.566-2.891-14.454-8.355-19.919C224.59-7.111 217.701-10 210.132-10z"
      />
    </svg>
  )
}
