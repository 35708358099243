import React, { useContext, useEffect, useState, useRef } from 'react';
import ReactPlayer from 'react-player';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

import {
  GlobalStateContext,
  GlobalDispatchContext,
} from '../context/GlobalContextProvider';
import {
  RecordedMixInfoDisplay,
  RecordedMixPlayerImage,
  RadioPlayerPlayButton,
} from '../components';
import '../styles/index.scss';
import {
  handleEnded,
  handleMixReady,
  handlePlayPause,
  handlePause,
} from '../dispatch';

export const BottomNavMixWrapper = () => {
  const [recordingState, setRecordingState] = useState(undefined);
  const [isPlaying, setIsPlaying] = useState(false);
  const player = useRef(ReactPlayer);
  const [localPlayerState, setLocalState] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
    hoursPlayed: 0,
    minutesPlayed: 0,
    secondsPlayed: 0,
    progress: 0,
  });

  const {
    hours,
    minutes,
    seconds,
    hoursPlayed,
    minutesPlayed,
    secondsPlayed,
    progress,
  } = localPlayerState;

  const globalState = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  const {
    liveMarquee,
    title,
    resident,
    infoDisplay,
    isLoading,
    img,
    playing,
    live,
    url,
  } = globalState;

  const handleDuration = duration => {
    let seconds = Math.floor(duration % 60);
    let minutes = Math.floor(duration / 60);
    let hours = Math.floor(minutes / 60);

    if (minutes >= 60) {
      hours = Math.round(minutes / 60);
      minutes = minutes % 60;
    } else {
      hours = 0;
    }

    setLocalState({
      ...localPlayerState,
      hours,
      minutes,
      seconds,
    });
  };

  const handleProgress = (played, loaded) => {
    const progress = played.played;

    let hoursPlayed = 0;
    let minutesPlayed = 0;
    let secondsPlayed = 0;

    if (Math.round(played?.playedSeconds) > 59) {
      minutesPlayed = Math.floor(played.playedSeconds / 60);

      if (minutesPlayed > 59) {
        hoursPlayed = Math.floor(minutesPlayed / 60);
        minutesPlayed = minutesPlayed % 60;
      } else {
        hoursPlayed = 0;
      }

      secondsPlayed = Math.round(played.playedSeconds % 60);
    } else {
      hoursPlayed = 0;
      minutesPlayed = 0;
      secondsPlayed = Math.round(played.playedSeconds % 60);
    }

    setLocalState({
      ...localPlayerState,
      hoursPlayed,
      minutesPlayed,
      secondsPlayed,
      progress,
    });
  };

  const playerStyle = {
    position: 'absolute',
    top: '-1000px',
    width: '1px',
    height: '1px',
    margin: '-1px',
  };

  useEffect(() => {
    if (infoDisplay === 'recorded' || recordingState) {
      document.body.setAttribute('data-layout-mix', 'visible');
    }
  }, [infoDisplay, recordingState]);

  useEffect(() => {
    if (
      infoDisplay === 'recorded' &&
      url !== 'https://half-moon.radiocult.fm/stream'
    ) {
      setRecordingState({
        url,
        title,
        resident,
        img,
      });
    }
  }, [infoDisplay, url]);

  if (!recordingState) {
    return null;
  }

  return (
    <div className="bottom-mix radio-bar">
      <ReactPlayer
        width="auto"
        height="auto"
        className="cloud-player"
        id="react-mix-player"
        ref={player}
        style={playerStyle}
        url={recordingState.url}
        volume={0.85}
        playing={infoDisplay === 'recorded' && globalState.playing}
        loop={globalState.loop}
        onProgress={handleProgress}
        onDuration={handleDuration}
        onPause={() => {
          if (infoDisplay === 'recorded') {
            handlePause(dispatch, live, 'recorded');
          }
        }}
        onBuffer={() => console.log('onBuffer')}
        onReady={() => {
          handleMixReady(dispatch);
        }}
        onEnded={() => {
          handleEnded(
            dispatch,
            globalState.playlist.length,
            globalState.list_curr_index
          );
        }}
        onError={error => console.error(error)}
        playsinline={true}
      />
      <RadioPlayerPlayButton
        isPlaying={infoDisplay !== 'recorded' ? false : playing}
        onClick={() => {
          // setRecoring(dispatch);
          handlePlayPause(dispatch, live, 'recorded');
        }}
      />
      <RecordedMixPlayerImage
        isLoading={isLoading}
        imgURL={img}
        imgAlt={title}
      />
      <RecordedMixInfoDisplay
        title={title}
        residents={resident}
        hoursPlayed={hoursPlayed}
        minutesPlayed={minutesPlayed}
        secondsPlayed={secondsPlayed}
        totalHours={hours}
        totalMinutes={minutes}
        totalSeconds={seconds}
        progress={progress}
        onSeek={amount => {
          player.current && player.current.seekTo(amount, 'fraction');
        }}
      />
      <div className={'column is-narrow'}>
        <Icon
          icon="times"
          className="icon-color"
          onClick={() => {
            setRecordingState(undefined);
            setLocalState({
              hours: 0,
              minutes: 0,
              seconds: 0,
              hoursPlayed: 0,
              minutesPlayed: 0,
              secondsPlayed: 0,
              progress: 0,
            });

            document.body.setAttribute('data-layout-mix', '');

            if (infoDisplay === 'recorded') {
              handlePause(dispatch, true, 'broadcast');
              dispatch({
                type: 'SET_INITIAL_LIVE',
              });
            }
          }}
          size="1x"
        />
      </div>
    </div>
  );
};
