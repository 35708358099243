/**
 * Returns a `/path/to/page` for use by gatsby's `Link` or `navigate` (see {@link SlideGenerator}) based on the incoming link type.
 * @category Utilities
 * @function linkResolver
 * @param {Object} linkObj - object type from the _meta of a HMBK Prismic data node
 * @property {String} link.type - Type of Prismic CMS link, corresponds to a CMS Custom Type
 * @property {String} link.uid - The slug to pass in to the returned template literal; used to navigate on the site.
 * @returns {String} the completed site link generated by uid of the link's page OR the index route, '/'
 */
function linkResolver(linkObj) {
  switch (linkObj.type) {
    case 'cms_guide':
      return `/hmbk-admin/guides/${linkObj.uid}`
    case 'event':
      return `/events/${linkObj.uid}`
    case 'feature':
      return `/editorial/${linkObj.uid}`
    case 'mix':
      return `/radio/${linkObj.uid}`
    case 'page':
      return `/${linkObj.uid}`
    case 'resident':
      return `/residents/${linkObj.uid}`
    default:
      return `/`
  }
}

export default linkResolver
