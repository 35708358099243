import dayjs from 'dayjs'

export const groupEvents = schedules => {
  const accumulator = {}

  const dateMapping = schedules.reduce((acc, event) => {
    const eventDate = dayjs(new Date(event.startDateUtc)).format('MM.DD')
    acc[eventDate] = acc[eventDate] ?? []
    acc[eventDate].push(event)
    return acc
  }, accumulator)

  Object.values(dateMapping).forEach(events => {
    // @ts-expect-error addition and subtraction of dates results in a number
    events.sort((a, b) => new Date(a.startDateUtc) - new Date(b.startDateUtc))
  })

  return dateMapping
}
