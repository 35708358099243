import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { animated, useSpring } from 'react-spring';

import { GlobalDispatchContext } from '../context/GlobalContextProvider';
import { NoShowsFallback, SingleDateScheduleEntries } from '../components';
import { formatDateTime } from '../utils';
import { closeSchedule, unmountSchedule } from '../dispatch';

const ANIMATION_DURATION = 200;

const fadeInAnimation = {
  from: { opacity: 1, maxHeight: 0 },
  to: { opacity: 1, maxHeight: 400 },
  config: {
    duration: ANIMATION_DURATION,
  },
};

const AnimatedScheduleDropdown = ({
  showData,
  timeNow,
  onClose,
  isAnimatingIn,
}) => {
  const [isUnmounting, setIsUnmounting] = useState(false);
  const animationProps = useSpring(
    isAnimatingIn
      ? fadeInAnimation
      : {
          to: { opacity: 0.5, maxHeight: 0 },
          from: { opacity: 1, maxHeight: 400 },
          config: {
            duration: ANIMATION_DURATION,
          },
        }
  );

  /**
   * Format date using {@link formatDateTime}.
   */
  const todaysDate = formatDateTime(timeNow, 'schedule-date-heading');

  return (
    <div className="schedule-bar container">
      <animated.div
        style={animationProps}
        className="columns is-multiline is-vcentered is-mobile dropdown"
      >
        <div className="column is-12 schedule-date-header">
          <div className="columns is-mobile is-vcentered">
            <div className="column">
              <p className="title is-size-4-desktop is-size-5-touch has-text-centered">
                {todaysDate}
              </p>
            </div>

            <div className="column is-narrow">
              <Link to="/schedule">
                <button
                  className="button is-small is-outlined is-rounded"
                  onClick={onClose}
                >
                  View Full Schedule
                </button>
              </Link>
            </div>
          </div>
        </div>

        {/*
        scheduleData !== undefined : render today's schedule
        scheduleData === undefined : render <NoShowsFallback />
      */}
        {showData && showData.length > 0 ? (
          <SingleDateScheduleEntries entries={showData} currentTime={timeNow} />
        ) : (
          <NoShowsFallback />
        )}
      </animated.div>
    </div>
  );
};

/**
 * Renders the schedule dropdown bar when `setOpen` is set to `true` in {@link ScheduleBar}.
 * @category Site Elements
 * @function ScheduleDropdown
 * @param {Object[]} showData - data array of the next two, if available, dates with scheduled shows
 * @param {Object} timeNow - dayJS object
 * @returns {jsx}
 */
function ScheduleDropdown({
  showData,
  timeNow,
  isScheduleOpen,
  scheduleUnmounting,
}) {
  const dispatch = useContext(GlobalDispatchContext);
  const animationProps = useSpring(fadeInAnimation);

  /**
   * Format date using {@link formatDateTime}.
   */
  const todaysDate = formatDateTime(timeNow, 'schedule-date-heading');

  if (scheduleUnmounting) {
    return (
      <AnimatedScheduleDropdown
        showData={showData}
        timeNow={timeNow}
        onClose={() => {}}
      />
    );
  }

  if (isScheduleOpen) {
    return (
      <AnimatedScheduleDropdown
        isAnimatingIn
        showData={showData}
        timeNow={timeNow}
        onClose={() => {
          setTimeout(() => {
            unmountSchedule(dispatch);
          }, ANIMATION_DURATION);
        }}
      />
    );
  }

  return null;
}

export default ScheduleDropdown;
