import React, { useContext } from 'react';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { LiveRadioPlayButton, ScheduleDropdown, UpcomingShow } from './index';
import {
  GlobalStateContext,
  GlobalDispatchContext,
} from '../context/GlobalContextProvider';
import { closeSchedule, toggleSchedule, handlePlayLive } from '../dispatch';

/**
 * Render the layout of the ScheduleBar using globalState.
 * Called by: {@link ScheduleBar}
 * Calls: {@link UpcomingShow}, {@link ScheduleDropdown}
 * @category Layout Helper
 * @function ScheduleBarLayout
 * @param {Object} timeNow - dayJS object originating from {@link TopNav}
 * @param {Object[]} upcomingShows - array of schedule data nodes, max 2 data objects
 * @returns {jsx}
 */
export default function ScheduleBarLayout({ timeNow }) {
  const dispatch = useContext(GlobalDispatchContext);
  const globalState = useContext(GlobalStateContext);

  const { live, scheduleOpen } = globalState;

  return (
    <div
      className={
        live
          ? 'schedule-bar container is-open is-live pr-2'
          : 'schedule-bar container is-open pr-2'
      }
      id="schedule-bar"
    >
      <div
        className="columns is-vcentered is-mobile is-variable is-2-tablet is-2-desktop is-2-widescreen is-1-mobile up-next"
        style={{ justifyContent: 'flex-end' }}
      >
        {/* <LiveRadioPlayButton /> */}
        {/* TO FIX LATER */}
        {/* <UpcomingShow showData={upcomingShows} timeNow={timeNow} /> */}
        <div className="column is-narrow">
          <button
            className="unstyled"
            aria-label="Toggle Schedule Dropdown"
            onClick={() => toggleSchedule(dispatch)}
          >
            <Icon icon="calendar-alt" size="lg" className="icon-color" />
          </button>
        </div>

        {/* <div className="column is-narrow">
            <Link to="/search">
              <Icon
                onClick={() =>               if (scheduleOpen) {
                closeSchedule(dispatch);
              }}
                icon="search"
                size="lg"
                className="icon-color"
              />
            </Link>
          </div> */}

        <div className="column is-narrow">
          <a
            onClick={() => {
              if (scheduleOpen) {
                closeSchedule(dispatch);
              }

              window.open(
                'https://app.radiocult.fm/embed/chat/half-moon?theme=midnight&amp;primaryColor=#f600ff&amp;corners=rounded',
                'Half Moon chat room',
                'width=600,height=600,'
              );
            }}
          >
            <Icon icon="comments" size="lg" className="icon-color" />
          </a>
        </div>
      </div>
    </div>
  );
}
