import React, { useContext } from 'react';
import { GlobalStateContext } from '../context/GlobalContextProvider';
import { LiveBroadcastInfoWrapper, RecordedMixInfoDisplay } from './index';

/**
 * Controls what is displayed by {@link RadioPlayer} based on `globalState`.
 * IF currently live
 *    render {@link LiveBroadcastInfoWrapper}
 * ELSE IF current URL exists
 *    render {@link RecordedMixInfoDisplay}
 * ELSE (not live AND no URL)
 *    render null
 * @category Layout Helper
 * @function RadioPlayerDisplay
 * @returns {jsx}
 */
export default function RadioPlayerDisplay({ isLiveOnly }) {
  const globalState = useContext(GlobalStateContext);
  const { liveMarquee, title, resident, infoDisplay } = globalState;

  if (infoDisplay === 'broadcast' || isLiveOnly) {
    return (
      <LiveBroadcastInfoWrapper
        liveTitle={liveMarquee.liveShowTitle}
        liveGuests={liveMarquee.liveShowGuests}
      />
    );
  } else {
    return null;
  }
}
