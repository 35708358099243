const contentTypeMapper = linkObj => {
  switch (linkObj.type) {
    case 'cms_guide':
      return 'guide'
    case 'event':
      return 'event'
    case 'feature':
      return 'editorial'
    case 'mix':
      return 'mix'
    case 'resident':
      return 'resident'
    default:
      return undefined
  }
}

export default contentTypeMapper
