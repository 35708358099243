import React from 'react'

/**
 * Renders the right arrow for the Hero.
 * @category Layout Helper
 * @function HeroRightArrow
 * @param {Function} onClick
 * @returns {jsx}
 */
export default function HeroRightArrow({ onClick }) {
  return (
    <svg
      className="hero-arrow__right"
      baseProfile="tiny"
      xmlns="http://www.w3.org/2000/svg"
      width={40}
      height={40}
      viewBox="-11.321 -11.321 315 315"
      onClick={onClick}
    >
      <path d="M82.228 297.36c-6.302 0-11.814-2.317-16.384-6.889-4.571-4.572-6.889-10.084-6.891-16.382V18.275c0-6.302 2.319-11.815 6.893-16.385C70.417-2.681 75.929-5 82.228-5c6.3 0 11.813 2.319 16.384 6.893l127.905 127.906c4.571 4.571 6.889 10.083 6.889 16.383 0 6.296-2.317 11.808-6.887 16.381L98.612 290.471c-4.57 4.572-10.082 6.889-16.384 6.889z" />
      <path
        fill="#FFF"
        d="M82.228 0c4.949 0 9.233 1.812 12.848 5.428l127.906 127.907c3.614 3.614 5.424 7.898 5.424 12.848 0 4.948-1.81 9.229-5.424 12.847L95.076 286.936c-3.615 3.616-7.899 5.424-12.848 5.424s-9.232-1.808-12.848-5.424c-3.617-3.618-5.426-7.898-5.427-12.848V18.275c0-4.949 1.81-9.233 5.427-12.848C72.996 1.811 77.28 0 82.228 0m0-10c-7.565 0-14.454 2.89-19.919 8.357-5.465 5.46-8.355 12.349-8.355 19.918v255.813c.001 7.565 2.891 14.452 8.354 19.918 5.464 5.465 12.352 8.354 19.92 8.354s14.456-2.889 19.919-8.354L230.053 166.1c5.465-5.47 8.353-12.356 8.353-19.918 0-7.566-2.889-14.454-8.353-19.919L102.147-1.644C96.684-7.11 89.795-10 82.228-10z"
      />
    </svg>
  )
}
