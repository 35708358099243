/**
 * Fetches the HMBK radio stream status.
 * Called by {@link ScheduleBar} to set initial TopNav status and to poll for radio live updates.
 * @category Utilities
 * @function fetchStreamStatus
 * @returns {String} One of "online" or "offline"
 */
export default async function fetchStreamStatus() {
  try {
    const streamResponse = await fetch(
      'https://api.radiocult.fm/api/station/half-moon/schedule/live',
      {
        headers: {
          'x-api-key': 'pk_6cd69e4aff67466ca32f2759d5ff3309',
        },
      }
    )
    const data = await streamResponse.json()
    return data.result
  } catch (error) {
    console.error('Error while fetching HMBK Radio Cult stream status.')
    console.error(error)
    return
  }
}
