import React, { useContext, useRef, useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { hot } from 'react-hot-loader';
import {
  RecordedMixPlayerImage,
  RadioPlayerDisplay,
  RadioPlayerPlayButton,
} from './index';
import { LiveMarkerAndText } from './helpers';
import { handleEnded, handleMixReady } from '../dispatch';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';
import { handlePlayPause, handlePlayLive } from '../dispatch';

/**
 * Returns the Radio Player layout element
 * @category Site Elements
 * @function RadioPlayer
 * @returns {jsx}
 */
function RadioPlayer() {
  const dispatch = useContext(GlobalDispatchContext);
  const globalState = useContext(GlobalStateContext);

  /**
   * Eliminate width and height = 0 errors by breaking ReactPlayer out of normal document flow and throwing it above the top of the page
   */
  const playerStyle = {
    position: 'absolute',
    top: '-1000px',
    width: '1px',
    height: '1px',
    margin: '-1px',
  };

  const player = useRef(ReactPlayer);

  const { isLoading, playing, infoDisplay, live } = globalState;

  return (
    <>
      <RadioPlayerPlayButton
        isPlaying={infoDisplay === 'recorded' ? false : playing}
        onClick={() => {
          if (infoDisplay === 'recorded') {
            handlePlayLive(dispatch);
          } else {
            handlePlayPause(dispatch, true, 'broadcast');
          }
        }}
      />

      <div
        className={
          globalState.isLoading
            ? 'column is-narrow is-hidden-mobile'
            : 'column is-narrow is-hidden-mobile is-loaded'
        }
      >
        <LiveMarkerAndText />
      </div>

      <div
        className={
          globalState.isLoading
            ? 'column text-truncate mix-data'
            : 'column text-truncate mix-data is-loaded'
        }
        id="now-playing"
      >
        <RadioPlayerDisplay isLiveOnly />
      </div>

      {infoDisplay !== 'recorded' && (
        <ReactPlayer
          key={`${globalState.playing}-${globalState.url}`}
          width="auto"
          height="auto"
          className="cloud-player"
          id="react-player"
          ref={player}
          style={playerStyle}
          url={globalState.url}
          volume={0.85}
          playing={globalState.playing}
          loop={globalState.loop}
          onBuffer={() => console.log('onBuffer')}
          onReady={() => {
            handleMixReady(dispatch);
          }}
          onEnded={() => {
            handleEnded(
              dispatch,
              globalState.playlist.length,
              globalState.list_curr_index
            );
          }}
          onError={error => console.error(error)}
          playsinline={true}
        />
      )}
    </>
  );
}

export default hot(module)(RadioPlayer);

// export const handleStop = () => {
//   this.setState({ url: null, playing: false });
// };

// export const handleToggleControls = () => {
//   const url = this.state.url;
//   this.setState(
//     {
//       controls: !this.state.controls,
//       url: null,
//     },
//     () => this.load(url)
//   );
// };

// export const handleToggleLight = () => {
//   this.setState({ light: !this.state.light });
// };

// export const handleToggleLoop = () => {
//   this.setState({ loop: !this.state.loop });
// };

// export const handleVolumeChange = e => {
//   this.setState({ volume: parseFloat(e.target.value) });
// };

// export const handleSetPlaybackRate = e => {
//   this.setState({ playbackRate: parseFloat(e.target.value) });
// };

// export const handleTogglePIP = () => {
//   this.setState({ pip: !this.state.pip });
// };

// export const handleEnablePIP = () => {
//   // console.log('onEnablePIP');
//   this.setState({ pip: true });
// };

// export const handleDisablePIP = () => {
//   // console.log('onDisablePIP');
//   this.setState({ pip: false });
// };

// export const handleSeekMouseDown = e => {
//   this.setState({ seeking: true });
// };

// export const handleSeekChange = e => {
//   this.setState({ played: parseFloat(e.target.value) });
// };

// export const handleSeekMouseUp = e => {
//   this.setState({ seeking: false });
//   this.player.seekTo(parseFloat(e.target.value));
// };

// export const handleProgress = state => {
//   // console.log('onProgress', state);
//   // We only want to update time slider if we are not currently seeking
//   if (!this.state.seeking) {
//     this.setState(state);
//   }
// };

// export const handleDuration = duration => {
//   // console.log('onDuration', duration);
//   this.setState({ duration });
// };

// export const handleClickFullscreen = () => {
//   screenfull.request(findDOMNode(this.player));
// };

export const X = () => (
  <iframe
    width="100%"
    height="20"
    scrolling="no"
    frameborder="no"
    allow="autoplay"
    src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1832410500&color=%23ff5500&inverse=false&auto_play=false&show_user=true"
  ></iframe>
);
