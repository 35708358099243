import React from 'react'

export default function AboutPageCredits() {
  return (
    <section className="section container pb-0">
      <div className="context text-block">
        <p className="title is-size-5 has-text-centered	">
          Powered by{' '}
          <a href="https://www.radiocult.fm" rel="noopener" target="_blank">
            Radio Cult
          </a>
        </p>
      </div>
    </section>
  )
}
