module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@mkitio/gatsby-theme-password-protect/gatsby-browser.js'),
      options: {"plugins":[],"password":"$HalfmoonBK2020","partialMatching":true,"pagePaths":["/hmbk-admin"]},
    },{
      plugin: require('../node_modules/@prismicio/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"hmbk-cms","pages":[{"type":"Feature","match":"/editorial/:uid","component":"/opt/build/repo/src/templates/Feature.js"},{"type":"Cms_guide","match":"/hmbk-admin/guides/:uid","component":"/opt/build/repo/src/templates/CMSGuide.js"},{"type":"Resident","match":"/residents/:uid","component":"/opt/build/repo/src/templates/Resident.js"},{"type":"Event","match":"/events/:uid","component":"/opt/build/repo/src/templates/Event.js"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
