import React, { useState, useEffect, useContext } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';
import { formatDateTime, sortUpcomingShowsArray } from '../utils';

import { RadioBar, ScheduleBar, ScheduleDropdown } from './index';

import { GET_UPCOMING_SHOWS } from '../queries';

dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * Renders the top navigation bar that contains {@link RadioBar} and {@link ScheduleBar}.
 * @category Site Elements
 * @function TopNav
 * @returns {jsx}
 */
export default function TopNav() {
  const globalState = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  const [nycTime, setNYCTime] = useState(formatDateTime(null, 'nyc-time'));

  const [upcomingShows, setUpcomingShowData] = useState([]);

  /**
   * @category useEffect
   * @name fetchUpcomingShows
   */
  useEffect(() => {
    const fetchUpcomingShows = async () => {
      const startOfDay = dayjs()
        .startOf('day')
        .toDate()
        .toISOString();
      const endOfDay = dayjs()
        .endOf('day')
        .toDate()
        .toISOString();
      const res = await fetch(
        `https://api.radiocult.fm/api/station/half-moon/schedule?startDate=${startOfDay}&endDate=${endOfDay}&timezone=UTC`,
        {
          headers: {
            'x-api-key': 'pk_6cd69e4aff67466ca32f2759d5ff3309',
          },
        }
      );

      const { schedules } = await res.json();
      setUpcomingShowData(schedules);
    };

    fetchUpcomingShows();
  }, []);

  /**
   * This `globalState` null return prevents ERROR #95313. Our render return depends on `globalState.live` to exist.
   * @see {@link BottomNav Related globalState situation in BottomNav}
   * @see {@link https://github.com/gatsbyjs/gatsby/issues/24264#issuecomment-631995753 Re: ERROR #95313 - To stop the error immediately, add a null check for the object}
   */
  if (!globalState) return null;

  const { live, scheduleOpen, scheduleUnmounting } = globalState;

  return (
    <div
      className={
        live ? 'radio-and-schedule-bar is-live' : 'radio-and-schedule-bar'
      }
    >
      {/* For use with measureTextWidth.js */}
      <canvas id="for-text-measuring" aria-hidden="true" />

      <RadioBar nycTime={nycTime} />
      <ScheduleDropdown
        showData={upcomingShows}
        timeNow={nycTime}
        isScheduleOpen={scheduleOpen}
        scheduleUnmounting={scheduleUnmounting}
      />
    </div>
  );
}
