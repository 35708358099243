import React, { useState, useContext, useEffect } from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider'
import { ScheduleBarLayout, OutsideClick } from './index'
import {
  fetchStreamStatus,
  formatDateTime,
  processDefaultMixData,
  sortUpcomingShowsArray,
} from '../utils'
import { GET_DEFAULT_MIX, GET_UPCOMING_SHOWS } from '../queries'
import { closeSchedule } from '../dispatch'

function ScheduleBar({ timeNow }) {
  const dispatch = useContext(GlobalDispatchContext)
  const globalState = useContext(GlobalStateContext)

  /**
   * useQuery that fetches the default recorded mix as defined in the CMS.
   * @category useQuery
   * @name getDefaultMix
   */
  const { loading, error, data: defaultMixData } = useQuery(GET_DEFAULT_MIX)

  /**
   * Set initial {@link RadioBar} state by checking the "online" status of the radio and the {@link getDefaultMix} `useQuery`.
   * IF radio is live/broadcasting:
   *    If so, set `globalState.live` to true.
   * ELSE IF a default mix has been set in the CMS:
   *    Process the mix data object and dispatch the corresponding status action.
   * ELSE (not live AND no default mix defined):
   *    Dispatch null.
   * @category useEffect
   * @name initializeRadioPlayerDisplay
   */
  useEffect(() => {
    const initializeRadioPlayerDisplay = async () => {
      try {
        /**
         * You need the `await`, else the promise doesn't resolve!
         */
        const { status: streamStatus, content } = await fetchStreamStatus()
        /**
         * `globalState.live` starts `false`; no else needed if not live.
         */
        if (streamStatus === 'schedule' || streamStatus === 'defaultPlaylist') {
          await dispatch({
            type: 'SET_INITIAL_LIVE',
          })

          await dispatch({
            type: 'MARQUEE_UPDATE',
            payload: {
              marquee: {
                liveShowTitle: content.name ?? content.title,
              },
            },
          })

          return
        } else if (defaultMixData) {
          processDefaultMixData(defaultMixData, dispatch)
        }
        /**
         *
         */
      } catch (error) {
        console.error('Error while fetching HMBK Radio Cult stream status.')
        console.error(error)
      }
    }
    initializeRadioPlayerDisplay()
  }, [])

  /**
   * Repeats the check above every 30 seconds, but also doesn't dispatch a context update unless needed.
   * IF (STREAM ONLINE; LIVE FALSE)
   *    dispatch "SET_LIVE"
   * ELSE IF (STREAM OFFLINE; LIVE TRUE)
   *    dispatch "SET_NOT_LIVE"
   * ELSE
   *    STREAM ONLINE; LIVE TRUE
   *    STREAM OFFLINE; LIVE FALSE
   *      no action needed (previous radio state reflects current {@link RadioPlayerDisplay})
   * Clears itself when unmounting.
   * @category useEffect
   * @name pollLiveStreamStatus
   */

  useEffect(() => {
    const pollLiveStreamStatus = setInterval(async () => {
      try {
        /**
         * You need the `await`, else the promise doesn't resolve!
         */
        const { status: streamStatus, content } = await fetchStreamStatus()

        if (
          (streamStatus === 'schedule' || streamStatus === 'defaultPlaylist') &&
          globalState.live === false
        ) {
          await dispatch({
            type: 'SET_LIVE',
          })

          await dispatch({
            type: 'MARQUEE_UPDATE',
            payload: {
              marquee: {
                liveShowTitle: content.name ?? content.title,
              },
            },
          })
        } else if (streamStatus === 'offAir' && globalState.live === true) {
          await dispatch({
            type: 'SET_NOT_LIVE',
          })
        }
      } catch (error) {
        console.error('Error while polling stream status!')
        console.error(error)
      }
    }, 30000)
    return () => clearInterval(pollLiveStreamStatus)
  }, [])

  /**
   * This globalState null return prevents ERROR #95313.
   * @see {@link https://github.com/gatsbyjs/gatsby/issues/24264#issuecomment-631995753 Re: ERROR #95313 - To stop the error immediately, add a null check for the object}
   */
  if (!globalState) return null

  /**
   * `globalState.scheduleOpen` ? OPEN LAYOUT : CLOSED LAYOUT
   * @see {@link BottomNav Related globalState situation in BottomNav}
   * @see {@link OutsideClick Related OutsideClick situation in BottomNav}
   */
  return globalState.scheduleOpen ? (
    <OutsideClick id={'schedule-bar'} onClick={() => closeSchedule(dispatch)}>
      <ScheduleBarLayout timeNow={timeNow} />
    </OutsideClick>
  ) : (
    <ScheduleBarLayout timeNow={timeNow} />
  )
}

export default ScheduleBar
