import React, { useRef, useState } from 'react';
import { InfoDisplayTimerElement } from './index';

/**
 * Renders the progress bar for a Soundcloud Mix
 * @category Layout Helper
 * @function RecordedMixInfoDisplay
 * @param {Number} totalHours - `localState.hours` from {@link RadioPlayer}
 * @param {Number} totalMinutes - `localState.minutes` from {@link RadioPlayer}
 * @param {Number} totalSeconds - `localState.seconds` from {@link RadioPlayer}
 * @param {Number} progress - `localState.progress` from {@link RadioPlayer}
 * @returns {jsx}
 */
export default function MixProgressBar({
  totalHours,
  totalMinutes,
  totalSeconds,
  progress,
  onSeek,
}) {
  const progressRef = useRef();
  const [tooltipText, setTooltipText] = useState('');
  const [tooltipPosLeft, setTooltipPosLeft] = useState(0);

  const onSeekBarClick = e => {
    const x = e.pageX - progressRef.current.offsetLeft,
      y = e.pageY - progressRef.current.offsetTop,
      clickedProgress =
        (x * progressRef.current.max) / progressRef.current.offsetWidth;

    onSeek(clickedProgress);
  };

  const onSeekBarMouseMove = e => {
    const x = e.pageX - progressRef.current.offsetLeft,
      y = e.pageY - progressRef.current.offsetTop,
      percent = (x * progressRef.current.max) / progressRef.current.offsetWidth;

    const totalDuration =
      totalHours * 60 * 60 + totalMinutes * 60 + totalSeconds;
    const position = totalDuration * percent;

    const hours = Math.floor(position / 3600);
    const minutes = Math.floor((position % 3600) / 60);
    const seconds = Math.floor((position % 3600) % 60);

    const positionStr = `${
      hours ? '0' + hours + ':' : ''
    }${minutes.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}:${seconds.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}`;

    setTooltipText(positionStr);
    setTooltipPosLeft(e.pageX - 60);
  };

  return (
    <>
      <progress
        label="Mix seek bar"
        id="seekbar"
        ref={progressRef}
        value={progress}
        max="1"
        onClick={onSeekBarClick}
        onMouseMove={onSeekBarMouseMove}
        className="seek-bar ml-2 mr-2"
      />
      <p id="progress-tooltip" style={{ left: tooltipPosLeft }}>
        {tooltipText}
      </p>
    </>
  );
}
